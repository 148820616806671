import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { addNewEmail, deleteEmail, fetchUserDetails, fetchUsers, handleApiError } from '../api/helpers'
import { AxiosResponse } from 'axios'
import { IUserDetails } from './MagicLink'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../redux/actions'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { InvoiceChannelItems } from './InvoiceChannel'
import UserListItem from '../components/UserListItem'
import Loading from '../components/Loading'
import Assets from '../components/Assets'
import { LocationEnum } from "../types/assets";

interface IDashboardProps {
  activeLanguage: "de" | "en";
}


const Dashboard = ({ activeLanguage }: IDashboardProps) => {
  const { formatMessage } = useIntl();

  // @ts-ignore
  const userData: IUserDetails = useSelector(state => state.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState("")
  const [duplicateEmail, setDuplicateEmail] = useState(false)
  const [userList, setUserList] = useState<IUserDetails[]>([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const getUserDetails = () => {
      setShowLoading(true)
      fetchUserDetails()
        .then(function (response: AxiosResponse<IUserDetails>) {
          const { data } = response
          dispatch(updateUser(data))
          if (!data.terms_accepted || !data.invoice_type) {
            history.push('/supplier-agreement')
          } else {
            getUsers()
          }
          setShowLoading(false)
        })
        .catch(function (error) {
          setShowLoading(false)
          handleApiError(error)
        });
    }
    getUserDetails()
    // eslint-disable-next-line
  }, [])

  const getUsers = () => {
    fetchUsers()
      .then(function (response: AxiosResponse<any>) {
        const { users } = response.data
        setUserList(users)
      })
      .catch(function (error) {
        handleApiError(error)
        toast.error(formatMessage({ id: 'get-user-fail' }))
      });
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setDuplicateEmail(false)
  }

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setShowLoading(true)
    addNewEmail(email)
      .then(function (response: AxiosResponse<any>) {
        setEmail("")
        setDuplicateEmail(false)
        getUsers()
        setShowLoading(false)
        toast.success(formatMessage({ id: 'add-email-success' }))
      })
      .catch(function (error) {
        setShowLoading(false)
        const errorMsg = handleApiError(error)
        if (errorMsg === "Duplicate user") {
          setDuplicateEmail(true)
        } else {
          toast.error(formatMessage({ id: 'add-email-fail' }))
        }
      });
  }

  const handleEmailDelete = async (email: string) => {
    setShowLoading(true)
    deleteEmail(email)
      .then(function (response: AxiosResponse<any>) {
        getUsers()
        setShowLoading(false)
      })
      .catch(function (error) {
        setShowLoading(false)
        toast.error(formatMessage({ id: 'delete-email-fail' }))
      });
  }

  const getSupplierNumberDisplay = () => {
    if (userData.company_name) {
      return `${userData.supplier_num} (${userData.company_name})`
    }
    return userData.supplier_num
  }


  if (!userData) {
    return <Loading show={showLoading} />
  }

  // @ts-ignore
  const selectedInvoiceChannel = InvoiceChannelItems[userData.invoice_type]


  return (
    <div className="dashboard-container">
      <Loading show={showLoading} />
      <section>
        <h2 className="dashboard-title">
          {formatMessage({ id: "dashboard-title" })}
        </h2>
        <h4 className="font-weight-bold mb-5">
          <span className="font-weight-light">
            {formatMessage({ id: "add-new-email-address" })}
          </span>
          {formatMessage({ id: "supplier-number" })}
          {getSupplierNumberDisplay()}
        </h4>

        <div className="dashboard-add-mail-container">
          <form onSubmit={handleEmailSubmit}>
            <p>{formatMessage({ id: "add-email-desc" })}</p>
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className="input-enbw input-sm mb-3"
              required
            />
            {
              duplicateEmail &&
              <p className="mb-3">
                {formatMessage({ id: "duplicate-email" })}
              </p>
            }
            <button type='submit' className="btn btn-enbw">
              {formatMessage({ id: "add-email" })}
            </button>
          </form>
        </div>
      </section>
      <section className="mt-5">
        <p className="section-title">
          {formatMessage({ id: "connected-emails" })}
        </p>
        <Row className="font-weight-bold">
          <Col xs={6}>{formatMessage({ id: "email" })}</Col>
          <Col xs={4}>{formatMessage({ id: "date-added" })}</Col>
        </Row>
        {
          userList.map((user, index) => {
            return (
              <UserListItem key={index} user={user} handleEmailDelete={handleEmailDelete} />
            )
          })
        }
      </section>
      <section className="mt-5">
        <p className="section-title">
          {formatMessage({ id: "approved-agreement" })}
        </p>
        <Assets activeLanguage={activeLanguage} location={LocationEnum.Dashboard} className="w-25" />
      </section>
      {
        selectedInvoiceChannel &&
        <section className="mt-5">
          <p className="section-title">
            {formatMessage({ id: "selected-invoice-channel" })}
          </p>
          <div className="invoice-item-fit mb-2">
            <p className="font-weight-bold">
              {formatMessage({ id: selectedInvoiceChannel.title })}
            </p>
            <div className="text-center">
              <Image src={selectedInvoiceChannel.iconSrc} />
            </div>
          </div>
          <a href={"/invoice-channel"} className="nav-link-enbw font-weight-medium">
            {formatMessage({ id: "change-invoice-channel" })}
          </a>
        </section>
      }
    </div>
  )
}

export default Dashboard