import React from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import { useIntl } from 'react-intl'


interface IConfirmDeleteProps extends ModalProps {
  email: string,
  handleEmailDelete: (email: string) => void
}

const ConfirmDelete = ({ email, handleEmailDelete, ...modalProps }: IConfirmDeleteProps) => {
  const { formatMessage } = useIntl();

  const handleConfirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    handleEmailDelete(email)
    modalProps.onHide()
  }

  return (
    <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...modalProps}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <p>
          {formatMessage({ id: "confirm-delete" }, { email })}
        </p>
        <p>
          {formatMessage({ id: "confirm-delete-warning" })}
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <button className="btn btn-light btn-round" onClick={modalProps.onHide}>
          {formatMessage({ id: "cancel" })}
        </button>
        <button className="btn btn-danger btn-round" onClick={handleConfirmDelete}>
          {formatMessage({ id: "delete" })}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDelete